// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --post-text-font-size: var(--font-size-large);
  --post-text-font-weight: var(--font-weight-medium);
  --post-owner-font-size: var(--font-size-small);
  --post-owner-font-weight: var(--font-weight-regular);
}

.post-list-item-container {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  cursor: pointer;
}
.post-list-item-container .image-container {
  grid-row: 1;
  grid-column: 1;
  width: 70px;
  height: 70px;
}
.post-list-item-container .image-container .post-image-thumbnail {
  width: 70px;
  height: 70px;
  --border-radius: 4px;
}
.post-list-item-container .image-container .post-image-thumbnail .post-image {
  width: 70px;
  height: 70px;
}
.post-list-item-container .image-container .pdf-icon-container {
  width: 100%;
  height: 100%;
  background-color: var(--mi-grey-color-22);
  display: flex;
  justify-content: center;
  align-items: center;
}
.post-list-item-container .image-container .pdf-icon-container app-icon {
  color: var(--mi-grey-color-6);
  --icon-size: 50px;
}
.post-list-item-container .image-container .post-media-container {
  width: 100%;
  height: 100%;
}
.post-list-item-container .image-container .post-media-container app-image-attachments-container {
  --image-attachments-container-padding-top: 0px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0px;
  --image-attachments-container-padding-end: 0px;
  --image-attachments-container-width: 70px;
  --image-attachments-container-height: 70px;
  --image-attachments-first-image-height: 70px;
}
.post-list-item-container .details-container {
  grid-row: 1;
  grid-column: 2;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.post-list-item-container .details-container .post-description-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 70px;
}
.post-list-item-container .details-container .post-description-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-container .text {
  font-size: var(--post-text-font-size);
  font-weight: var(--post-text-font-weight);
  color: var(--mi-black-color-2);
}
.post-list-item-container .details-container .media-only-post-container {
  width: 100%;
  height: 70px;
}
.post-list-item-container .details-container .media-only-post-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 70px;
}
.post-list-item-container .details-container .media-only-post-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-container .text {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-black-color-2);
}
.post-list-item-container .details-container .pdf-only-post-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 9px;
}
.post-list-item-container .details-container .pdf-only-post-container .file-name {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-list-item-container .details-container .pdf-only-post-container .file-size {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-list-item-container .details-container .post-owner {
  font-size: var(--post-owner-font-size);
  font-weight: var(--post-owner-font-weight);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
