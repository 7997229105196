import { Group, GroupMember, Post } from 'src/app/services/groups/group.model';
import { AOEvent, EventPost, Participant } from 'src/app/services/yeti-protocol/event';
import { UserProfile } from '../yeti-protocol/auth/mi';
import { ClinicalCase } from '../yeti-protocol/clinical-case';
import {GroupListItem, Poll} from '../yeti-protocol/chatter-api';
import { ParentType } from '../yeti-protocol/chatter-api-schema';
import {PersonalMediaGalleryDocument} from '../yeti-protocol/personal-media-gallery';

export enum CreatePostAction {
  ADD_IMAGES = 'add-images',
  ATTACH_DOCUMENT = 'attach-document',
  ADD_VIDEO = 'add-video'
}

export interface PostData {
  title?: string;
  description?: string;
  file?: any;
  url?: string;
  type: string;
  isAnonymous?: boolean;
  watermarked?: boolean;
  documents?: Array<string>;
  parentIds: Array<string>;
  parentType: ParentType;
  facultyOnly?: boolean;
  needAdvice?: boolean;
  objectId?: string;
  pollId?: string;
}

export interface PostWithCaseData {
  type: string;
  isAnonymous: boolean;
  needAdvice?: boolean;
  objectId: string;
  parentIds: Array<string>;
  parentType: ParentType;
  facultyOnly?: boolean;
  description?: string;
  pollId?: string;
}

export interface UpdatePostData {
  title?: string;
  description?: string;
  url?: string;
  type: string;
  isAnonymous?: boolean;
  watermarked?: boolean;
  documents?: Array<string>;
  parentId: string;
  parentType: ParentType;
  facultyOnly?: boolean;
}

export enum ContentParentType {
  GROUP = 'group',
  EVENT = 'event',
  GENERAL = 'general'
}

export interface ParentData {
  parents: Array<Group | GroupListItem | AOEvent>;
  parentsType: ParentType;
}

export interface CreatePostState {
  imageFiles: Array<PersonalMediaGalleryDocument>;
  documentFiles: Array<PersonalMediaGalleryDocument>;
  postContent: string;
}

export abstract class CreateContentStrategy {
  post: Post | EventPost;
  clinicalCase: ClinicalCase;
  user: UserProfile;
  shareCaseInPost: boolean;
  parentsData: Array<ParentData>;
  contentParentType: ContentParentType;
  createPostState: CreatePostState;
  poll: Poll;

  abstract openCreatePost(
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean,
    createPostState?: CreatePostState,
    poll?: Poll,
    ): void;
  abstract openCreateCase(caseId?: string, replaceUrl?: boolean): void;
  abstract openCreatePoll(replaceUrl?: boolean): void;
  abstract openAddImages(): void;
  abstract openAttachDocument(): void;
  abstract openAddVideo(): void;
  abstract get isPostTypeAttachment(): boolean;
  abstract get isPostTypeLink(): boolean;
  abstract get isPostTypeText(): boolean;
  abstract createPost(postData: PostData, preventNavigation?: boolean): Promise<Post | EventPost>;
  abstract createPostWithACase(postWithCaseData: PostWithCaseData, preventNavigation?: boolean): Promise<Post | EventPost>;
  abstract updatePost(updatePostData: UpdatePostData): Promise<Post | EventPost>;
  abstract determinePostType(url: string, attachments: Array<string>): string;
  abstract getParentMembers(searchTerm: string,
    start?: number,
    count?: number,
    excludedUserIds?: Array<string>): Promise<Array<GroupMember | Participant>>;
  abstract promptUserAboutNotSavedState(): Promise<any>;
  abstract get parentIds(): Array<string>;
  abstract get parent(): Group | AOEvent | UserProfile;
  abstract get allowAnonymousPost(): boolean;
}
