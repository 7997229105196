import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Case } from '../../services/yeti-protocol/chatter-api';
import { ImageAttachment } from 'src/app/services/attachments.model';
import { PersonalMediaGalleryDocument } from 'src/app/services/yeti-protocol/personal-media-gallery';
import { ClinicalCase } from '../../services/yeti-protocol/clinical-case';

@Component({
  selector: 'app-clinical-case-list-item',
  templateUrl: './clinical-case-list-item.component.html',
  styleUrls: ['./clinical-case-list-item.component.scss'],
})
export class ClinicalCaseListItemComponent implements OnInit {

  @Input() case: Case;
  @Input() clinicalCase: ClinicalCase;
  @Input() showRadioBtn = false;
  @Input() showBanner = false;
  @Input() bannerText: string;
  @Input() itemTitle?: string;

  @Output() listItemClicked: EventEmitter<Case | ClinicalCase> = new EventEmitter();
  @Output() radioButtonChanged: EventEmitter<ClinicalCase> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  get caseImages(): Array<ImageAttachment> {
    return this.getCaseImagesWithCorrectUrls(
      this.case?.caseDiscussion?.media?.mediaAttachments || this.clinicalCase?.media?.mediaAttachments);
  }

  get totalMediaImages(): number {
    return (this.case?.caseDiscussion?.media?.totalImages || this.clinicalCase?.media?.totalImages) ?? 0;
  }

  get totalMediaVideos(): number {
    return (this.case?.caseDiscussion?.media?.totalVideos || this.clinicalCase?.media?.totalVideos) ?? 0;
  }

  getCaseImagesWithCorrectUrls(images: Array<PersonalMediaGalleryDocument>): Array<ImageAttachment> {

    if (!images?.length) {
      return [];
    }

    if (this.case?.watermarked || this.clinicalCase?.watermarked) {
      return images.map(img => {
        return {
          ...img,
          url: img?.watermarkedPreviewUrl,
          fullImageUrl: img?.fullWatermarkUrl
        };
      })
    } else {
      return images.map(img => {
        return {
          ...img,
          url: img?.previewUrl,
          fullImageUrl: img?.fullUrl
        };
      })
    }
  }

  caseListItemClicked(): void {
    this.listItemClicked.emit(this.case || this.clinicalCase);
  }

  onRadioSelect(): void {
    this.radioButtonChanged.emit(this.clinicalCase);
  }
}
